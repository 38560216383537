<template>
  <div class="detail-share-post">
    <v-header title="分享海报"/>
    <div class="share-post">
      <img :src="sharePostUrl" @load="loadSuccess" v-if="shareMode && shareMode.length" @error="loadError">
    </div>
    <div class="operation">
      <van-radio-group v-model="shareMode" class="mode-radio-group">
        <van-radio name="preview">标准分享</van-radio>
        <van-radio name="cover">只分享封面</van-radio>
      </van-radio-group>
      <div class="operation-text">
        <div>长按上图，保存图片或发送给朋友~</div>
        <!--<div class="gray">新用户通过识别此二维码首次登录,</div>-->
        <!--<div class="gray">双方都会获得{{yanzhiFilter(config.recommendAwardYanzhi)}}奖励严值哦~上不封顶~</div>-->
      </div>
    </div>
  </div>
</template>

--------------------------------------
<script>
  import {mapGetters} from 'vuex'
  import {taskV2Api} from "../../api/v2/taskV2Api";

  export default {
    data() {
      return {
        shareMode: '',
      }
    },

    mounted() {
      this.switchShareMode('preview');
    },

    computed: {
      ...mapGetters(['userInfo']),

      sharePostUrl() {
        if (!this.shareMode || !this.shareMode.length) {
          return '';
        }
        const mode = this.shareMode;
        const applet = 'h5';
        const token = localStorage.getItem('token') || ''
        // let url = window.location.protocol + '//' + window.location.host + `/home${window.location.search}`;
        return `/api/applet/v2/search-svr/appletHomeSharePost/post.jpg?mode=${mode}&applet=${applet}&Token=${token}`;
      }
    },

    methods: {
      // 切换分享模式
      switchShareMode(mode) {
        this.$loading('努力生成中~');
        setTimeout(() => {
          this.shareMode = mode;
        }, 500)
      },

      loadSuccess() {
        this.$loading.close();
      },

      loadError() {
        this.$loading.close();
        this.$toast.error('失败');
      },
    }
  }
</script>

--------------------------------------
<style lang="less" scoped>
  .detail-share-post {
    background: #f2f4f7;
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  .operation {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 100px;
    background: #fff;
    padding-top: 30px;
    /*border-top-left-radius: 30px;*/
    /*border-top-right-radius: 30px;*/
    /*border-top: 1px solid #e3e3e3 ;*/
  }

  .share-post {
    position: fixed;
    top: 40px;
    bottom: 125px;
    width: 100vw;
    overflow-y: scroll;

    img {
      width: 100vw;
    }
  }

  .mode-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 64px;
    color: #aaa;

    .active {
      color: #02A9F1;
    }
  }

  .operation-text {
    padding-top: 25px;
    font-size: 12px;
    color: #151d36;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
