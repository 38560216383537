import { render, staticRenderFns } from "./homeSharePost.vue?vue&type=template&id=6ada2edc&scoped=true&"
import script from "./homeSharePost.vue?vue&type=script&lang=js&"
export * from "./homeSharePost.vue?vue&type=script&lang=js&"
import style0 from "./homeSharePost.vue?vue&type=style&index=0&id=6ada2edc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ada2edc",
  null
  
)

export default component.exports